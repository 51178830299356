import { Component, OnInit, Injectable } from '@angular/core';
import { CustomService } from '../services/custom.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-removeuser',
  templateUrl: './removeuser.component.html',
  styleUrl: './removeuser.component.css'
})
export class RemoveuserComponent implements OnInit {
  humanReadableTime = (timeDate: string) => {
    moment.locale('fi');
    const fixedDate = moment(timeDate);
    return fixedDate.format("MM.DD.YYYY HH:MM");
  }

  confirmCode: any = null;
  infoText: any = "";
  selectedCard : any;
  freeCardsName : any = [];
  freeCardsCount : any = [];
  freeNumbers : any = [];
  cardDetails = {};
  tmpurl: any = [];
  count = 0;
  emailCard;
  note;
  loggedUserEmail: any;
  restaurant;
  cardType: any;
  errorNote;
  donotsend = true;
  removedUsers : any = [];
  constructor(
    private customService: CustomService,
    private authService: AuthService,
    private router: Router
  ) { }
  ngOnInit(): void {
    console.log("conf", this.confirmCode);
    this.confirmCode = localStorage.getItem("token");
    console.log("this.confirmCode", this.confirmCode);

    this.loggedUserEmail = localStorage.getItem('email');

    const getRemovedUsersPayload = {
      "loggedUserEmail": this.loggedUserEmail.replace(/['"]+/g, ''),
      "email": this.emailCard,
      "token": this.confirmCode,
    };
    this.customService.getRemovedUsers(getRemovedUsersPayload)
      .subscribe(response => {
        this.removedUsers = response;
    });
    this.loggedUserEmail = localStorage.getItem('email');
  }
  removeUser = (emailcard, note): void => {
    console.log("loggedUserEmail:", this.loggedUserEmail);
    console.log("emailCard", this.emailCard)
    const jsonText = {
      "loggedUserEmail": this.loggedUserEmail.replace(/['"]+/g, ''),
      "email": this.emailCard,
      "token": this.confirmCode,
    }

    const atpos = this.emailCard?.indexOf("@");
    const dotpos = this.emailCard?.lastIndexOf(".");

    console.log(this.loggedUserEmail);
    if(validateEmail(emailcard) && this.donotsend){
      this.customService.removeUser(jsonText)
        .subscribe(response => {
          console.log({response});
          if (response['success']) {
            this.router.navigateByUrl('/management');
          }
          console.log({response});
          this.infoText = response;
      });
    } else {
      console.log("ei tainnu sähköpostia olla...");
      this.errorNote = "ei ole toimiva sähköpostiosoite"
    }
    console.log({jsonText})
  }
}

const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

