<app-header></app-header>
<div class="globalContent">
  <div class="loginContainer" >
    <div class="loginChild" style="padding:20px;">
        <div class="loginService" style="color:#ccc"><h1>Hei,</h1></div>
        <br><br>
         Lähetimme sinulle sähköpostiin kirjautumista varten linkin!
         <br>
         <br>
         Mikäli sinulla on oikeus päästä järjestelmään.
         <br>
       <br />&nbsp;<br />
    </div>
  </div>
</div>
