import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {
  permission: any = false;
  loggedInEmail: any = '';
  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit(): void {
    const emailFromStorage = localStorage.getItem('email');
    this.loggedInEmail = emailFromStorage ? JSON.parse(emailFromStorage) : '';

    const token = localStorage.getItem("token");
    console.log("headertoken", token);
    if(token){
      this.authService.checkPermissionLevel(token)
        .subscribe(response => {
          console.log("response", response);
          this.permission = response['permission'] === "true";
        });
      }

      console.log({permission: this.permission});
  }

}
