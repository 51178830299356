<div class="headerBackground">
  <br /><br />
  <div><a href="/management"><img src="/assets/images/NoHo-hallinta-logo_valkoinen.svg" style="height: 100px"></a></div>
  <span class="menu" style="text-decoration: none;">
  <a href="/management" *nfIf='loggedInEmail'>kortit</a>
      <a href="/removeuser" *ngIf="permission">&nbsp;-&nbsp; poisto</a>
</span>
&nbsp;<br>
&nbsp;<br>
</div>
