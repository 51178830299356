<app-header></app-header>
<div class="content">
  <div class="globalContent">
    <div class="subHeader">
      <h2 style="color:#ccc;line-height: 30px;">ASIAKKAAN TIEDOT POISTETAAN CUSTOBARISTA</h2>
    </div>
    <div id="flexWrapper">
      <div class="columns">
        <div id="col1" style="color:#E97D76">
         {{infoText['message']}}

        </div>
      </div>
    </div>
    <div id="flexWrapper">
      <div class="columns">
        <div id="col1">
          <h2 style="color:#ccc;">Poistettavan asiakkaan sähköposti:</h2>

          <div class="validation" *ngIf="errorNote">{{errorNote}}</div>
            <input type="text" id="title" name="emailCard" size="40" [(ngModel)]="emailCard"><br>
        </div>
      </div>
    </div>
    <br>
    <div>
      <button class="button" (click)="removeUser(emailCard, note)" style="background: #fa7571; border: 1px solid #fa7571;">Lähetä kortti sähköpostiin.</button><br><bR>
    </div>
    <h4>Poistumassa 24h kuluttua.</h4>
    <div class="grid-container">
      <!-- Assuming headers are like titles for each column -->
      <div class="grid-row header">
        <div class="grid-item">Poistettava</div>
        <div class="grid-item">Poistaja</div>
        <div class="grid-item">Pvm</div>
        <div class="grid-item">Muokattu</div>
      </div>

      <!-- Loop through each row and then place each item in its own div, under the corresponding header -->
      <div *ngFor="let item of removedUsers" class="grid-row">
        <div class="grid-item">{{ item.remove_email }}</div>
        <div class="grid-item">{{ item.issuer }}</div>
        <div class="grid-item">{{ humanReadableTime(item.created) }}</div>
        <div class="grid-item">{{ humanReadableTime(item.modified) }}</div>
      </div>
    </div>
  </div>
</div>

