<app-header></app-header>
<div class="grid-container">
  <!-- Assuming headers are like titles for each column -->
  <div class="grid-row header">
    <div class="grid-item">Saaja</div>
    <div class="grid-item">Myöntäjä</div>
    <div class="grid-item">Kortin tyyppi</div>
    <div class="grid-item">Ravintola</div>
    <div class="grid-item">lähetetty pvm</div>
    <div class="grid-item">käyttetty pvm</div>
  </div>

  <!-- Loop through each row and then place each item in its own div, under the corresponding header -->
  <div *ngFor="let item of rows" class="grid-row">
    <div class="grid-item">{{ item.sendemail ? item.sendemail : item.email }}</div>
    <div class="grid-item">{{ item.issuer }}</div>
    <div class="grid-item">{{ item.card_type }}</div>
    <div class="grid-item">{{ item.restaurant }}</div>
    <div class="grid-item">{{ humanReadableTime(item.send_date, "ei tietoa") }}</div>
    <div class="grid-item">{{ humanReadableTime(item.used_date, "")}}</div>
  </div>
</div>


