import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-logininstruction',
  templateUrl: './logininstruction.component.html',
  styleUrls: ['./logininstruction.component.css']
})
export class LogininstructionComponent implements OnInit {
  valid: any = false;

  constructor() { }

  ngOnInit(): void {
  }

}
