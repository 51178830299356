import { Component, OnInit, Injectable } from '@angular/core';
import { CustomService } from '../services/custom.service';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})

@Component({
  selector: 'app-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.css']
})
export class ManagementComponent implements OnInit {
  permission: any = false;
  freeCardsError: string| undefined;
  freeCardsName: any = [];
  freeCardsCount : any = [];
  joro: string | undefined;
  confirmCode: any = null;
  tmpEmail: any;
  isLoggedIn: any;
  isLoggedEmail: any;
  loggedInEmail: any;

  encodeUrl(param: string): string {
    return encodeURIComponent(param);
  }

  constructor(
    private customService: CustomService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    if(!this.confirmCode){
      this.confirmCode = localStorage.getItem("token");
      //check status?
    }
    if(this.confirmCode){
      this.authService.checkPermissionLevel(this.confirmCode)
        .subscribe(response => {
          console.log("response", response);
          this.permission = response['permission'] === "true";
        });
    }

    const emailFromStorage = localStorage.getItem('email');
    this.loggedInEmail = emailFromStorage ? JSON.parse(emailFromStorage) : '';
    console.log({loggedInEmail: this.loggedInEmail});

    this.freeCardsName = ['All access -kortti', 'Viihdemaailma Ilona VIP', 'NoHo -työntekijä',
    'Noho hallinto', 'NightAccess Silver', 'NightAccess Gold', 'NightAccess Black'];

    //TODO: poista kovakoodattu data
    this.freeCardsName = this.customService.getFreeCardsCount({email: this.loggedInEmail});

    const removeCards = ["Night Access Gold", "huonokortti"];
    this.customService.getFreeCardsCount({email: this.loggedInEmail})
      .subscribe(response => {
      this.customService.getFreeCardsCount({email: this.loggedInEmail}).subscribe(response => {
        console.log("response", response);
        const filteredResponse = response
         .sort((a, b) => {
          // Ensinnäkin järjestetään card_type:n mukaan
          const cardTypeComparison = a.card_type.localeCompare(b.card_type);

          // Jos card_type on sama, järjestetään ravintolan mukaan
          if (cardTypeComparison === 0) {
            return a.restaurant.localeCompare(b.restaurant);
          } else {
            return cardTypeComparison;
          }
        });
        // Muutetaan Objeck keys metodia ja varmistetaan, että se on yhteensopiva ngFor loopin kanssa.
        console.log({filteredResponse});
        this.freeCardsName = Object.keys(filteredResponse).map(key => filteredResponse[key]);
      });
    }, err =>{
      this.freeCardsError = "Internet yhteys ??";
    });
  }

  routeToMultipleReceiver = (index: any, restaurant: any): void => {
    this.router.navigateByUrl(`/multiplereceiver?type=${index}&restaurant=${restaurant}&ctype=${index}`);
  }

  routeToCard = (index: any, restaurant: any): void => {
    this.router.navigateByUrl(`/carddetails?type=${index}&restaurant=${restaurant}&ctype=${index}`);
  }

  checkToGetCards = (index: any, restaurant: any): void => {
    this.router.navigateByUrl(`/carddistributiontracker?type=${index}&restaurant=${restaurant}&ctype=${index}`);
  }

  routeToAdd = (): void => {
    this.router.navigateByUrl('/addnumbers');
  }

}
