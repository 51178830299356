<app-header></app-header>

<div class="content" role="main">
    <form (ngSubmit)="onSubmit()" [formGroup]="myForm">
    <div>
      <editor
          apiKey="no-api-key"
          [disabled]="false"
          [id]="'editor' + uniqueId"
          [init]="{ plugins: '',
                      base_url: '/tinymce',
                      menubar: false,
                      statusbar: false,
                      suffix: '.min'
                    }"
          [formControl]="myForm.controls.body"
          [inline]="false"
          toolbar="bold"
         selector="#editor"
         value="foo"
          >
      </editor>
    </div>
    <input type="submit" value ="submit">
  </form>

