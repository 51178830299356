<app-header></app-header>
<div class="content">
  <div class="globalContent">
    <div class="subHeader">
      <h2 style="color:#ccc;">
      {{ this.selectedCard.name }} {{ this.selectedCard.restaurant }}</h2>
    </div>

    <div id="flexWrapper">
      <div class="columns">
        <div id="col1">
          <h2 style="color:#ccc;">Kortin saajan sähköposti:</h2>

          <div class="validation" *ngIf="errorNote">{{errorNote}}</div>
            <input type="text" id="title" name="emailCard" size="40" [(ngModel)]="emailCard"><br>
            <h2 style="color:white">Muistio:</h2>
            <textarea name="note" id="note" style="overflow: hidden; word-wrap: break-word; resize: none; height: 160px;" [(ngModel)]="note">

            </textarea>
        </div>
      </div>
    </div>

    <div>
      <button class="button" (click)="saveCard(emailCard, note)" style="background: #fa7571; border: 1px solid #fa7571;">Lähetä kortti sähköpostiin.</button><br><bR>
    </div>
  </div>
</div>

