import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  private hostname = window.location.hostname;
  constructor(private httpClient: HttpClient) { }

  getConfig(){
    let apiUrl = "";
    if (this.hostname.includes('nohohallinta')){
      apiUrl = "https://api.nightaccess.fi";
    } else {
      apiUrl = "http://localhost:3000";
    }

    return apiUrl;
  }
}
