import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }
  getLoginStatus(validationCode: string){
    const headers = {"Content-Type":"application/json"};
    const body = {"t": validationCode };
    return this.httpClient.post(this.configService.getConfig() +'/login/status', body, { headers, responseType: 'json' });
  }

  checkPermissionLevel(token: string){
    const headers = {"Content-Type":"application/json"};
    const body = {"token": token };
    return  this.httpClient.post(this.configService.getConfig() + '/checkpermissionlevel/', body, { headers, responseType: 'json' });
  }

  createLogin(email: string){
    const headers = {"Content-Type":"application/json"};
    const body = {"email": email };
    return this.httpClient.post(this.configService.getConfig() + '/managementlogin/', body, { headers, responseType: 'json' });
  }
  checkToken(token: string, nightAccessblue: boolean = false){
    const headers = {"Content-Type":"application/json"};
    const body = {"t": token, nightAccessblue };
    return this.httpClient.post(this.configService.getConfig() + '/managementlogin/status', body, { headers, responseType: 'json' });
  }
}
