import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { LogininstructionComponent } from './logininstruction/logininstruction.component';
import { ManagementComponent } from './management/management.component';
import { CardComponent } from './card/card.component';
import { AddnumbersComponent } from './addnumbers/addnumbers.component';
import { FaqEditorComponent } from './faq-editor/faq-editor.component';
import { MultiplereceiverComponent } from './multiplereceiver/multiplereceiver.component';
import { ManagecarddetailsComponent } from './managecarddetails/managecarddetails.component';
import { RemoveuserComponent } from './removeuser/removeuser.component';
import { CarddistributiontrackerComponent } from './carddistributiontracker/carddistributiontracker.component';

const routes: Routes = [
  {path: '', component: LoginComponent},
  {path: 'LoginComponent', component: LoginComponent},
  {path: 'LoginInstruction', component: LogininstructionComponent},
  {path: 'management', component: ManagementComponent},
  {path: 'addnumbers', component: AddnumbersComponent},
  {path: 'faq-editor', component: FaqEditorComponent},
  {path: 'carddetails', component: CardComponent},
  {path: 'managecarddetails', component: ManagecarddetailsComponent},
  {path: 'removeuser', component: RemoveuserComponent},
  {path: 'multiplereceiver', component: MultiplereceiverComponent},
  {path: 'carddistributiontracker', component: CarddistributiontrackerComponent},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
