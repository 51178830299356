import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CdkTextareaAutosize, TextFieldModule } from '@angular/cdk/text-field';
import { MatInputModule } from '@angular/material/input';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponent } from './login/login.component';
import { ManagementComponent } from './management/management.component';
import { RemoveuserComponent } from './removeuser/removeuser.component';
import { ManagecarddetailsComponent } from './managecarddetails/managecarddetails.component';
import { MultiplereceiverComponent } from './multiplereceiver/multiplereceiver.component';
import { CardComponent } from './card/card.component';
import { AddnumbersComponent } from './addnumbers/addnumbers.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from "@angular/forms";
import { LogininstructionComponent } from './logininstruction/logininstruction.component';
import { FaqEditorComponent } from './faq-editor/faq-editor.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { ReactiveFormsModule } from '@angular/forms';
import { EditorModule } from '@tinymce/tinymce-angular';
import { CarddistributiontrackerComponent } from './carddistributiontracker/carddistributiontracker.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    ManagementComponent,
    RemoveuserComponent,
    MultiplereceiverComponent,
    ManagecarddetailsComponent,
    CardComponent,
    AddnumbersComponent,
    FaqEditorComponent,
    CarddistributiontrackerComponent,
    LogininstructionComponent
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    BrowserAnimationsModule,
    DragDropModule,
    ReactiveFormsModule,
    MatCardModule,
    EditorModule,
    TextFieldModule,
    MatProgressBarModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
