import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';

interface AuthResponse {
  isLoggedIn: boolean;
  // include other properties that might be part of the response
}


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  confirmCode: any = null;
  tmpEmail: any;
  isLoggedIn: any;
  selectedcard = 'Night Access Silver';
  isLoggedEmail: any;
  constructor(
    private authService: AuthService,
    private router: Router
  ) {}
  ngOnInit() {
    if (window.location.href && window.location.href.includes("/?email=")) {
      const tmpcode = window.location.href.split('=')[1];
      this.router.navigateByUrl(`/LoginInstruction?email=${tmpcode}`);
    }
    if (window.location.href && window.location.href.includes("/?t=")) {
      const tmpcode = window.location.href.split('=')[1];
      this.confirmCode = tmpcode.split('&')[0];
      localStorage.setItem('token', JSON.stringify(this.confirmCode));
      console.log("confirmCode", this.confirmCode);
      this.authService.checkToken(this.confirmCode)
        .subscribe(response => {
          console.log("tassa on", response);
          let r = Object.entries(response).flat();
          this.tmpEmail = r[1];
          this.isLoggedEmail = r[1];
          this.isLoggedIn = r[7];
          console.log('this.isLoggedIn',this.isLoggedIn)
          //this.currentPage = "loggedUser";
          localStorage.setItem('email', JSON.stringify(this.isLoggedEmail));
          localStorage.setItem('logged', JSON.stringify(this.isLoggedIn));
          if(this.isLoggedIn){
            this.router.navigateByUrl('/management');
          }
        }, error => {
          console.log("error:", error);
        });
    }
    if(!this.confirmCode){
      console.log("conf", this.confirmCode);
      this.confirmCode = localStorage.getItem("token");
      this.authService.checkToken(this.confirmCode).subscribe((response: any) => {
        if (response.isLoggedIn) {
          console.log("Authenticated:", response);
        } else {
          console.log("Authentication failed or no result", response);
          this.router.navigateByUrl('/login');
        }
      }, error => {
        console.log("Network or Response error:", error);
        this.router.navigateByUrl('/error');
      });
    }
  }
}
