<app-header></app-header>
<div class="globalContent">
  <div class="loginContainer" >
    <div class="loginChild">
      <br> &nbsp; <br>
        <div class="loginService">Kirjaudu palveluun</div>
        <form name="loginForm" label="formData">
          <br />
          <div class="borderContainer">
            <input id="tmpEmail" type="newEmail" class="smoothBorder" placeholder="S&auml;hk&ouml;postiosoite" #tmpEmail  />
          </div>
        </form>
        <br />&nbsp;<br />
        <button id="loginbutton" class="button" (click)="addEmail(tmpEmail.value)"  mat-flat-button color="success" style="background: #fa7571; border: 1px solid #fa7571;">L&auml;het&auml; kirjautumislinkki</button>
    </div>
  </div>
</div>
