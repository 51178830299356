<app-header></app-header>
<div class="content">
  <div class="globalContent">
      <div class="subHeader">
        <br />&nbsp;<br />
          Lisää vapaita numeroita korteille
        <br />&nbsp;<br />
      </div>
      <span style="font-size: 18px;">Valitse alla olevasta listasta yksi kortti ja lataa siihen kuuluva excel tiedosto.</span>
  </div>
      <br>&nbsp;<br>
      <div id="flexWrapper">
        <div class="columns">
          <div id="col1" style="color: #606060;" class="background-black">
          </div>
          <div id="col2" style="color: #606060; font-size: 16px" class="background-black">
              Kortti
          </div>
          <div id="col3" style="color: #606060; font-size: 16px" class="background-black">
            Vapaita numeroita
          </div>
        </div>
      </div>
      <div id="flexWrapper" *ngFor="let oneCard of freeCardsName; let i = index">
        <div class="columns">
          <div id="col1" [ngClass]="{'col-background': i % 2 == 0, '': i % 2 != 0}">
               <input type="radio" id="selection" name="selection" [value]="oneCard.name">
          </div>
          <div id="col2" [ngClass]="{'col-background': i % 2 == 0, '': i % 2 != 0}">
              {{ oneCard }}
          </div>
          <div id="col3" [ngClass]="{'col-background': i % 2 == 0, '': i % 2 != 0}">
            {{ freeCardsCount[i] }}
          </div>
        </div>
      </div>
   <div style="display:block; margin: 0 auto;text-align: center;">
    <div style="display:inline-block;">
      <br>
      <p *ngIf="!loadedCsvFiles.length">Ei ladattua tiedostoa</p>
      <div *ngIf="loadedCsvFiles.length">
        <div *ngFor="let oneFile of loadedCsvFiles; let i = index">
          <span>{{ oneFile.name }}</span>
          <a (click)="fileRemove(i)" style="color: #9F2046">&nbsp;Poista</a>
        </div>
      </div>
      <br>&nbsp;<br>
      <button class="loadingButton" onclick="document.getElementById('myFile').click()" style="width:256px; height:30px; line-height:30px;">Lataa csv-tiedosto</button>
        <input class="loadingButton" type="file" id="myFile" name="filename" style="display:none" (click)="fileUpload()">
      <br>&nbsp;<br>

      <p id="uploadedFile"></p>

      <input class="genericButton col-background" value="Lisää numerot kortille" name="button" (click)="addNumbersToCard()">
    </div>
  </div>
</div>
<app-footer></app-footer>
