import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, retry, catchError} from 'rxjs/operators';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})

export class CustomService {
  private loginUrl = this.configService.getConfig() + '/backend/login';
  private cardsCountUrl = this.configService.getConfig() + '/countCard';
  private saveUrl = this.configService.getConfig() + '/saveCard';
  private addNumbersUrl = this.configService.getConfig() + '/addNumbers';
  private getFreeCardsUrl = this.configService.getConfig() + '/getfreecards';
  private reservedCardUrl = this.configService.getConfig() + '/management/reservecard'
  private numbersUrl = this.configService.getConfig() + '/numbers/';
  private removeUserUrl = this.configService.getConfig() + '/management/removeuser';
  private getremovedusers = this.configService.getConfig() + '/management/getremovedusers';
  private getCardDispatchListUrl = this.configService.getConfig() + '/management/carddispatchlist';
  private counts = [592,98,121,23,265,12,2];
  private numbers = [3437897892,5837897892,5983789784,5983789785,5983789786,5983789787, 5983789788, 5983789789, 5983789790,5983789791];

  constructor(
    private httpClient: HttpClient,
    private configService: ConfigService
  ) { }

  createLogin(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.loginUrl, data, { headers, responseType: 'json' });
  }

  saveCard(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.saveUrl, data, { headers, responseType: 'json' });
  }

  addNumbers(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.addNumbersUrl, data, { headers, responseType: 'json' });
  }

  removeUser(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.removeUserUrl, data, { headers, responseType: 'json' });
  }

  getRemovedUsers(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.getremovedusers, data, { headers, responseType: 'json' });
  }

  reserveCard(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.reservedCardUrl, data, { headers, responseType: 'json' });
  }
  getCardDispatchList(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post(this.getCardDispatchListUrl, data, { headers, responseType: 'json'})
            .pipe(
              retry(3),
              catchError(err => {
                return err;
              })
            )
  }
  getFreeCardsCount(data: object) {
    const headers = {"Content-Type":"application/json"};
    return this.httpClient.post<any>(this.getFreeCardsUrl, data, { headers, responseType: 'json'})
            .pipe(
              retry(3),
              catchError(err => {
                return err;
              })
            )
  }

  getCardNumbers(cardType: string) {
    return this.httpClient.get<any>(this.numbersUrl + cardType);
  }

  //TODO: poista mockit
  getFreeCardsCountMockData() {
    return this.counts;
  }

  getFreeCards(){
    return this.httpClient.get<any>(this.getFreeCardsUrl);
  }

  setFreeCardsCountMockData(lista: any) {
    this.counts = lista;
  }

  getCardNumbersMockData() {
    return this.numbers;
  }
  editCardNumbersMockData(lista: any) {
    this.numbers = lista;
  }
}
