  import { Component, OnInit} from '@angular/core';
  import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
  import { FormControl, FormGroup, Validators } from "@angular/forms";
  import { ReactiveFormsModule } from '@angular/forms'; // Import ReactiveFormsModule

  declare var tinymce: any;

  @Component({
    selector: 'app-managecarddetails',
    templateUrl: './managecarddetails.component.html',
    styleUrl: './managecarddetails.component.css',
    providers: [
      { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
    ]
  })

  export class ManagecarddetailsComponent implements OnInit {
    uniqueId = Math.floor(Math.random() * 1000).toString(); // Generate a unique identifier
    initialContent: any= '<p><b>Siteby.fi </B>oma editori jolla asiakas ei voi sekoilla.</p>';
    public myForm = new FormGroup({
      body: new FormControl({value: this.initialContent, disabled: false}, [Validators.required, this.maxLength])
    });

    description: any = "";

    public onSubmit() {
      console.log("myForm.controls.body", this.myForm.controls.body.value);
      console.log("Submitted!");
      this.initialContent = this.myForm.controls.body.value;
    }
    maxLength(control: FormControl): { [key: string]: any } | null {
      const value: string = control.value || '';
      if (value.length > 10) {
        return { 'maxLengthExceeded': true };
      }
      return null;
    }
    constructor(
    ) { }

    ngOnInit(): void {
      this.myForm.get('body')?.enable();
    }


  }
