import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { CustomService } from '../services/custom.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  currentEmail: any = null;
  loginUser: any = {};
  valid: any = false;
  invalidResponse: any;
  validResponse: any;
  createdLoginLink: any = false;

  //loginUser = {username:'', password: ''};
  constructor(
    private customService: CustomService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  addEmail(value: string) {
    console.log({value});
    this.setCurrentEmail(value);
  }

  setCurrentEmail(newEmail: any){
    console.log("newEmail", newEmail);
    this.currentEmail = newEmail;
    this.loginUser.email = newEmail;
    console.log("currentEmail", this.currentEmail);
    console.log("loginUser.email", this.loginUser.email);
    this.createLoginLink();
  }
  createLoginLink = (): void => {
    console.log('mitaaa', this.loginUser.email)
    if (this.loginUser.email) {
      this.validateEmail();
      if (this.valid) {
        this.authService.createLogin(this.loginUser.email)
          .subscribe(response => {
            this.createdLoginLink = true;
            this.valid = true;
            this.validResponse = 'Kirjautumislinkki on lähetetty sähköpostiin';
            this.invalidResponse = undefined;
            this.router.navigateByUrl(`/LoginInstruction`);
          }, error => {
            this.invalidResponse = 'Kirjautumislinkin luonti epäonnistui';
            this.validResponse = undefined;
            this.valid = false;
          });
      }
    }
  }

  validateEmail = (): boolean => {
    var emailID = this.loginUser.email;
    const atpos = emailID.indexOf("@");
    const dotpos = emailID.lastIndexOf(".");
    if (atpos < 1 || ( dotpos - atpos < 2 )) {
      this.invalidResponse = 'Anna oikea sähköpostiosoite';
      this.validResponse = undefined;
      this.valid = false;
      return false;
    }
    this.valid = true;
    return( true );
  }

  login = (): void => {
    if (this.loginUser.username.length) {
      this.customService.createLogin(this.loginUser)
        .subscribe(response => {
          this.router.navigateByUrl('/management');
      });
    }

    //TODO: poista navigointi tuotantoonsiirrossa
    this.router.navigateByUrl('/management');
  }
}
