<app-header></app-header>
<p>multiplereceiver works!</p>
<form [formGroup]="form" (ngSubmit) ="onSubmit()">
  <mat-form-field [style.fontSize]="12">
    <mat-label>Vastaanottajat</mat-label>
    <textarea matInput
              formControlName="receivers"
              cdkTextareaAutosize
              #autosize="cdkTextareaAutosize"
              cdkAutosizeMinRows="5"
              cdkAutosizeMaxRows="20"></textarea>
  </mat-form-field>
  <button *ngIf="showButton" type="submit">Submit</button>

</form>
Progress of sending emails:


<mat-progress-bar mode="determinate" value="40"></mat-progress-bar>
