import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-faq-editor',
  templateUrl: './faq-editor.component.html',
  styleUrl: './faq-editor.component.css'
})

export class FaqEditorComponent implements OnInit {
  todos: any[] = [
    { title: "Item 1", description: "Description 1" },
    { title: "Item 2", description: "Description 2" },
    { title: "Item 3", description: "Description 3" },
    { title: "Item 4", description: "Description 4" },
    { title: "Item 5", description: "Description 5" }
  ];

  activeEditItem: any = null;
  activeEditIndex: number | null = null;

  newItem: any = {
    title: "",
    description: ""
  };


dropped(event: CdkDragDrop<string[]>) {
  console.log('Dropped Event:', event);
  const changes=event.currentIndex-event.previousIndex;
  console.log({changes});
    if (event.previousContainer === event.container) {
        console.log("mov", event.container.data, event.previousIndex, event.currentIndex);
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
        console.log("transferArrayItem", event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex)
    }

/*
  if (event.previousContainer === event.container.data) {
    console.log('Same container move');
    if (event.container.data && event.previousIndex !== undefined && event.currentIndex !== undefined) {
      // Käytä slice-metodia päivittämään taulukon järjestystä
      this.todos = [...this.todos];
      moveItemInArray(this.todos, event.previousIndex, event.currentIndex);
    }
  } else {
    console.log('Different container move');
    if (
      event.previousContainer.data &&
      event.container.data &&
      event.previousIndex !== undefined &&
      event.currentIndex !== undefined
    ) {
      // Käytä slice-metodia päivittämään taulukon järjestystä
      this.todos = [...this.todos];
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    }
  }
  */

  console.log('After Drop:', this.todos);

  this.sendData();  // Ensure that sendData is called after the drop event
}

  editItem(item: any, index: number) {
    this.activeEditItem = { ...item };
    this.activeEditIndex = index;
  }
  // Metodi, joka tallentaa muokatut tiedot
  saveEdit() {
    if (this.activeEditIndex !== null) {
      this.todos[this.activeEditIndex] = { ...this.activeEditItem };
      this.sendData();
      this.cancelEdit(); // Peruuttaa muokkaustilan
    }
  }

  cancelEdit() {
    this.activeEditItem = null;
    this.activeEditIndex = null;
  }

  addItem() {
    if (this.newItem.title.trim() !== "" && this.newItem.description.trim() !== "") {
      this.todos.push({ ...this.newItem });
      this.newItem.title = "";
      this.newItem.description = "";
      this.sendData();
    }
  }

  confirmRemoveItem(index: number) {
    const confirmRemove = confirm("Are you sure you want to remove this item?");
    if (confirmRemove) {
      this.removeItem(index);
    }
  }

  removeItem(index: number) {
    if (index >= 0 && index < this.todos.length) {
      this.todos.splice(index, 1);
      this.sendData();
    }
  }

  loadData() {
    this.httpClient.get<any[]>('http://localhost:3000/management/getfaq')
      .subscribe(
        (data: any) => {
          console.log('Server response:', data);

          // @ts-ignore
          if (Array.isArray(data.faqData) && data.faqData.length > 0) {
            // Käytä data.faqData suoraan
            const newData = JSON.parse(data.faqData[0].faq_data);
            console.log("Transformed data:", newData);

            this.todos = newData;
          } else {
            console.error("Invalid response format");
            this.todos = []; // tai käsittele virhetilanne tarvittaessa
          }
        },
        error => {
          console.error("Error loading data:", error);
          this.todos = []; // tai käsittele virhetilanne tarvittaessa
        }
      );
  }
sendData() {
  console.log("this todo sendatasssa:", this.todos);

  // Tarkista, että this.todos on taulukko ennen kuin lähetät sen
  const email = "lennart.takanen@gmail.com"; // vaihda tähän
  if (Array.isArray(this.todos)) {
    this.httpClient.post('http://localhost:3000/management/savefaq', { faqData: this.todos, email })
      .subscribe(response => {
        console.log(response);
        // Tee jotain vastauksen kanssa, jos tarpeen
      }, error => {
        console.error(error);
        // Käsittele virhe, jos lähetys epäonnistuu
      });
  } else {
    console.error('Invalid request. FAQ data should be an array.');
    // Käsittele virhe, jos this.todos ei ole taulukko
  }
}



  constructor(private httpClient: HttpClient) {}

  ngOnInit(): void {
    this.loadData();
  }
}
