import { Component, OnInit } from '@angular/core';
import { CustomService } from '../services/custom.service';


@Component({
  selector: 'app-addnumbers',
  templateUrl: './addnumbers.component.html',
  styleUrls: ['./addnumbers.component.css']
})
export class AddnumbersComponent implements OnInit {
  freeCardsName : any = [];
  freeCardsCount : any = [];
  loadedCsvFiles : any[] = [];
  title = 'cards';
  loggedInEmail: any;
  constructor(private customService: CustomService) { }


  ngOnInit(): void {
    const emailFromStorage = localStorage.getItem('email');
    this.loggedInEmail = emailFromStorage ? JSON.parse(emailFromStorage) : '';

    this.fileUpload();
    this.freeCardsName = ['All access -kortti', 'Viihdemaailma Ilona VIP', 'NoHo -työntekijä',
    'Noho hallinto', 'NightAccess Silver', 'NightAccess Gold', 'NightAccess Black'];

    //TODO: poista kovakoodattu data
    this.freeCardsCount = this.customService.getFreeCardsCountMockData();

    this.customService.getFreeCardsCount({email: this.loggedInEmail})
      .subscribe(response => {
        this.freeCardsCount = response ;
    });
  }

  /*  en oikein tiiä miten tää sit kopataan oikeesti */
  fileUpload = () : void => {
    const myFiles = document.getElementById("myFile");
    myFiles?.addEventListener('change', event => {

      const result = (event.target as HTMLInputElement).files;
      let fileName :string = "";
      if(result){
        fileName = result[0].name;
        this.loadedCsvFiles.push(result[0]);
        this.loadedCsvFiles = [...new Set(this.loadedCsvFiles)];
      }
     console.log(result);
     console.log("f: " + fileName);
    });
  }
  addNumbersToCard = (): void => {
    const selection = document.getElementsByName("selection") as NodeListOf<HTMLInputElement>;

    for (var i = 0; i < selection.length; i++) {
      let ele = selection[i];
      if(selection[i].checked===true){
        const selectedRole = selection[i];
        this.customService.addNumbers({'selectedCard': selectedRole.value})
          .subscribe(response => {
            this.loadedCsvFiles = [];
        });
        break;
      }
    }
  }

  loadCsv = (): void => {
    console.log("toimii");
  }

  fileRemove = (index): void => {
    this.loadedCsvFiles.splice(index, 1);
  }
}
