<app-header></app-header>
<p>faq-editor works!</p>
<div style="text-align: left; max-width: 300px;">
  <div class="row">
    <div class="col-6">
      <h5 class="title">TODOS</h5>
      <div class="items-container" cdkDropList (cdkDropListDropped)="dropped($event)">
        <mat-card *ngFor="let item of todos; let i = index" cdkDrag>
          <mat-card-content>
            <!-- Display the title and description -->
            <h6 *ngIf="activeEditIndex !== i" (click)="editItem(item, i)">{{item.title}}</h6>
            <p *ngIf="activeEditIndex !== i">{{item.description}}</p>

            <!-- Edit buttons -->
            <div *ngIf="activeEditIndex === i">
              <input type="text" [(ngModel)]="activeEditItem.title" placeholder="Title">
                <textarea [ngModel]="newItem.description" (ngModelChange)="newItem.description = $event" placeholder="New Description"></textarea>
              <textarea [(ngModel)]="activeEditItem.description" placeholder="Description"></textarea>
              <button (click)="saveEdit()">Save</button>
              <button (click)="cancelEdit()">Cancel</button>
            </div>
          </mat-card-content>
          <!-- Remove button -->
          <button (click)="confirmRemoveItem(i)" *ngIf="activeEditIndex !== i">Remove</button>
        </mat-card>
      </div>

      <!-- Input fields for adding new items -->
      <input type="text" [(ngModel)]="newItem.title" placeholder="New Title">
      <textarea [(ngModel)]="newItem.description" placeholder="New Description"></textarea>
      <button (click)="addItem()">Add</button>
    </div>
  </div>
</div>

